import axios from "axios";
// require("dotenv").config();

console.log(process.env.REACT_APP_API);
export default axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
});
