import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`

  :root {
    --blue-gradient: linear-gradient(180deg, #293A64, #050E25);
  }

  @font-face {
    font-family: HindVadodara;
    src: url('/fonts/HindVadodara-Bold.ttf');
  }

  @font-face {
    font-family: KoHoRegular;
    src: url('/fonts/KoHo-Regular.ttf');
  }

  @font-face {
    font-family: KoHoMedium;
    src: url('/fonts/KoHo-Medium.ttf');
  }

  @font-face {
    font-family: KoHoBold;
    src: url('/fonts/KoHo-Bold.ttf');
  }

  @font-face {
    font-family: KoHoSemi;
    src: url('/fonts/KoHo-SemiBold.ttf');
  }

  @font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  html {
    font-family: KoHoSemi;
    color: white;
    box-sizing: border-box;

  }
  
  *,*::before,*::after {
  box-sizing: inherit;
}

  body {
    overflow: auto;
    position: relative;
    background: var(--blue-gradient);
    margin: 0;
    margin: 0;
    font-family: "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.15px;
  }
  

  h2,h3,h4,h5,h6 {
    font-family: KoHoBold;
    margin: 0;
  }

  h4, h5 {
    font-family: KoHoRegular;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 23.4px;
  }

  h1 {
    font-family: HindVadodara;
    margin: 0;
    font-size: 110px;
    line-height: 115px;
  }

  h2 {
    font-size: 45px;
  }

  h4 {
    font-size: 25px;
  }

  h5 {
    font-size: 20px;
  }

  img {
  width: 100%;
  }

  a {
    color: white;
    text-decoration: none;
  }

  input {
    width: 316px;
    height: 50px;
    border: none;
    background-color: rgba(255,255,255,0.3);
    margin: 0px 25px 30px 0px;
    font-size: 20px;
    padding-left: 15px;
    ::placeholder {
      color: rgba(0,0,0,0.3);
      opacity: 1;
    }
  }

  input:focus {
    background-color: rgba(255,255,255,0.5);
  }

  textarea:focus {
    background-color: rgba(255,255,255,0.5);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  textarea {
    width: 675px;
    height: 128px;
    border: none;
    background-color: rgba(255,255,255,0.3);
    font-size: 20px;
    padding: 15px 0px 0px 15px;
    ::placeholder {
      color: rgba(0,0,0,0.3);
      opacity: 1;
    }
  }

  button {
    font-size: 20px;
    color: black;
    background-color: #3BACF2;
    border-radius: 4px;
    border: none;
  }

  button:disabled {
    background-color: #56555A;
  }

  .animated {
    visibility: hidden;
}

  .circle {
    height: 10px;
    width: 10px;
    background-color: rgba(0,0,0,0);
    border-radius: 50%;
    display: inline-block;
    border: 2px solid #FFF;
  }

  .line {
    height: 1px;
    width: 175px;
    background-color: #56555A;
  }

  .mobile {
      display: none;
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown__content {
      display: none;
      position: absolute;
      right: -17px;
      top: 36px;
  }

  .dropdown--active .dropdown__content {
      display: block;
  }

  .ReactModal__Content {
    padding: 0px !important;
  }

  @media (max-width: 758px) {
    .circle {
      height: 3px;
      width: 3px;
    }
    .desk {
      display: none !important;
    }
    .mobile {
      display: inherit !important;
    }
    
    h1 {
      font-size: 36px;
      line-height: 38px;
    }
    h2 {
      font-size: 18px;
      line-height: 23px;
    }
    h4 {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
    input {
      max-width: 310px;
      margin-right: 0px;
    }
    textarea {
      max-width: 297px;
      margin-right: 0px;
      height: 90px;
      border: none;
      background-color: rgba(255,255,255,0.3);
      font-size: 18px;
      padding: 15px 15px 0px 15px;
    }
  }

`;

export default GlobalStyles;