import { BrowserRouter, Switch, Route } from "react-router-dom"
import Privacy from "./components/Privacy"
import Cocktail from "./pages/Cocktail"
import Home from "./App"
function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/cocktail" component={Cocktail} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
