// import { useAddUser } from '@/hooks/catalogs/users'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useEffect, useState } from 'react'

//** Hooks imports */
// import { useGetAllCountries } from 'src/hooks/catalogs/country'

import { useGetAreacodeCountries } from '../hooks/catalogs/useGetAreacodeCountries'


export default function CountrySelect({
    setSelectedCountry,
    size = 'small',
    otherProps,
    id
}) {

    const [value, setValue] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const { countries, getAreacodeCoutries } = useGetAreacodeCountries();
    const options = [...countries.map((country) => country.name)]

    //   const { user } = useAddUser()
    const [currentId, setCurrentId] = useState(0)

    useEffect(() => {
       getAreacodeCoutries()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const country = countries.find((country) => country.id === currentId)
        setSelectedCountry(country)
        setValue(country?.name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries])

    useEffect(() => {
        if(value){
            setSelectedCountry(countries.find((country) => country.name === value))
        }else{
            setSelectedCountry(
               null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if (id) {
            setCurrentId(id)
        }
    }, [id, setSelectedCountry])


    return (
        <div style={{width: '50%'}}>
            <Autocomplete

                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                id='controllable-states-demo'
                options={options}
                sx={{ width: 200 }}
                style={{ ...otherProps }}
                size={size}
                renderInput={params => <TextField 
                                            {...params} 
                                            label={'Country'}
                                            sx={{
                                                '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                                                     borderColor: '#2535A8'
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': { color: '#2535A8' },
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px'
                                                }
                      }}
                                         />}
            />
        </div>
    )
}
