import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { ContactSection as Contact } from "./components/Contact";
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
} from "react-simple-dropdown";
import Modal from "react-modal";

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ ease: "none" });

//Styles

const Header = styled.header`
  position: relative;
  background-color: #293a64;
  .title {
    top: 62%;
    left: 17%;
    position: absolute;
    h1:nth-child(2) {
      font-size: 90px;
    }
  }
  .nav-desk {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 50px;
    height: 62px;
    width: 100%;
    background-color: rgba(196, 196, 196, 0.2);
    z-index: 9;
    transition: top 0.5s ease-in-out, background-color 1s ease-in-out;
    .logo {
      height: 50px;
      width: min-content;
      margin-left: 56px;
      cursor: pointer;
    }
    .nav-desk.active {
      top: 0px;
    }
    .menu {
      margin-right: 10%;
    }
    .nav-link {
      font-family: KoHoRegular;
      font-weight: 400;
      font-size: 20px;
      margin: 0 20px;
      cursor: pointer;
    }
    &.notOnTop {
      top: 0px;
      background-color: rgba(196, 196, 196, 0.4);
      transition: top 0.5s ease-in-out, background-color 1s ease-in-out;
    }
  }
  @media (max-width: 758px) {
    .title {
      h1:nth-child(2) {
        font-size: 36px;
      }
    }
    .nav-desk {
      top: 0px;
      height: 52px;
      background-color: #0e1d39;
      .logo {
        height: 25px;
        margin-left: 30px;
      }
      .menu {
        margin-right: 10%;
      }
      &.notOnTop {
        top: 0px;
        background-color: #0e1d39;
        transition: top 0s ease-in-out, background-color 0s ease-in-out;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-left: 0;
      align-items: flex-end;
    }
    li {
      padding: 0px 24px 16px;
    }
    .menu-mobile {
      display: flex;
      position: fixed;
      top: 16px;
      right: 16px;
      z-index: 10;
      align-items: flex-end;
      img {
        cursor: pointer;
      }
    }
    .dropdown-menu {
      width: 100%;
      background: linear-gradient(180deg, #0e1d39, #293a64);
      padding: 20px 0 4px;
    }
  }
`;

const About = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blue-gradient);
  padding: 50px 0px;
  h2 {
    margin-left: 50px;
    padding-right: 90px;
  }
  p {
    max-width: 692px;
  }
  @media (max-width: 758px) {
    h2 {
      margin-left: 26px;
      padding-right: 29px;
      white-space: nowrap;
    }
    p {
      padding-right: 20px;
    }
  }
`;

const Paralax = styled.div`
  background-image: url("/images/Miami2.jpg");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 758px) {
    min-height: 340px;
  }
`;

const MissionStatement = styled.section`
  background: var(--blue-gradient);
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  h2 {
    margin-left: 50px;
    padding-right: 90px;
  }
  p {
    max-width: 692px;
  }
  @media (max-width: 758px) {
    height: 250px;
    h2 {
      margin-left: 26px;
      padding-right: 29px;
    }
    p {
      padding-right: 20px;
    }
  }
`;

const ProductOffering = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  background-image: url("/images/Hotel.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .back-transparency {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 760px;
    height: 115px;
    background-color: rgba(5, 14, 37, 0.4);
  }
  img {
    position: absolute;
    top: 0px;
    z-index: -1;
  }
  @media (max-width: 758px) {
    height: 148px;
    .back-transparency {
      height: 42px;
      width: 244px;
    }
    h2 {
      font-size: 20px;
      text-align: center;
    }
  }
`;

const Services = styled.section`
  height: 105vw;
  position: relative;
  .img_hotel {
    position: absolute;
    top: 56px;
    left: 0px;
    width: 60%;
    z-index: -1;
  }
  .img_hospital {
    position: absolute;
    top: 54vw;
    right: 0px;
    width: 30%;
    z-index: -1;
  }
  .img_roadway {
    position: absolute;
    top: 54vw;
    right: 33vw;
    width: 29.6%;
    z-index: -1;
  }

  h2 {
    position: absolute;
    top: -133px;
    right: -141.8px;
    letter-spacing: 7px;
    text-align: center;
    line-height: 42px;
  }

  h4 {
    font-size: 20px;
  }

  .bullets-services-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(41, 58, 100, 0.8),
      rgba(5, 14, 37, 0.8)
    );
    text-align: center;

    width: 45%;
    height: 607px;
    top: 156px;
    right: 40px;
  }

  .bullets-services-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(41, 58, 100, 0.8),
      rgba(5, 14, 37, 0.8)
    );

    width: 38%;
    height: 540px;
    top: 60vw;
    left: 118px;
  }

  @media (max-width: 758px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 30px 0px 20px;
    h2 {
      position: relative;
      top: inherit;
      right: inherit;
      letter-spacing: 7px;
      line-height: 20px;
    }
    h4 {
      font-size: 16px;
    }
    .img_hotel {
      position: relative;
      width: 90px;
      height: 147.05px;
      top: inherit;
      left: inherit;
    }
    .img_hospital {
      position: relative;
      width: 90px;
      height: 147.05px;
      top: inherit;
      right: inherit;
    }
    .img_roadway {
      position: relative;
      width: 90px;
      height: 147.05px;
      top: inherit;
      right: inherit;
    }
    .bullets-services-1 {
      position: relative;
      background: linear-gradient(
        180deg,
        rgba(5, 14, 37, 0.8),
        rgba(41, 58, 100, 0)
      );
      width: 90%;
      height: auto;
      top: inherit;
      right: inherit;
      padding: 15px 0px 15px;
    }
    .bullets-services-2 {
      position: relative;
      background: linear-gradient(
        180deg,
        rgba(41, 58, 100, 0),
        rgba(5, 14, 37, 0.8)
      );
      width: 90%;
      height: auto;
      top: inherit;
      left: inherit;
      padding: 15px 0px 15px;
      h4 {
        padding: 10px 0px;
      }
    }
  }
`;

const Industrial = styled.section`
  height: 100vw;
  position: relative;

  .img_planta {
    position: absolute;
    top: 120px;
    right: 0px;
    width: 65%;
    z-index: -1;
  }
  .img_turbine {
    position: absolute;
    top: 50vw;
    left: 0px;
    width: 29.7%;
    z-index: -1;
  }
  .img_buildings {
    position: absolute;
    top: 50vw;
    left: 33vw;
    width: 32%;
    z-index: -1;
  }

  h2 {
    position: absolute;
    top: -137px;
    right: -141.8px;
    letter-spacing: 7px;
    text-align: center;
    line-height: 42px;
  }

  h4 {
    font-size: 20px;
  }

  .bullets-industrial-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    text-align: center;
    background: linear-gradient(
      180deg,
      rgba(41, 58, 100, 0.8),
      rgba(5, 14, 37, 0.8)
    );

    width: 37%;
    height: 507px;
    top: 190px;
    left: 90px;
  }

  .bullets-industrial-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    text-align: center;
    background: linear-gradient(
      180deg,
      rgba(41, 58, 100, 0.8),
      rgba(5, 14, 37, 0.8)
    );

    width: 38%;
    height: 520px;
    top: 56vw;
    right: 30px;
  }

  @media (max-width: 758px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding-bottom: 30px;
    h2 {
      position: relative;
      top: inherit;
      right: inherit;
      letter-spacing: 7px;
      line-height: 20px;
    }
    h4 {
      font-size: 16px;
    }
    .img_planta {
      position: relative;
      top: inherit;
      right: inherit;
      width: 90px;
      height: 147.05px;
    }
    .img_turbine {
      position: relative;
      top: inherit;
      left: inherit;
      width: 90px;
      height: 147.05px;
    }
    .img_buildings {
      position: relative;
      top: inherit;
      left: inherit;
      width: 90px;
      height: 147.05px;
    }
    .bullets-industrial-1 {
      position: relative;
      background: linear-gradient(
        180deg,
        rgba(5, 14, 37, 0.8),
        rgba(41, 58, 100, 0)
      );
      width: 90%;
      height: auto;
      top: inherit;
      left: inherit;
      padding: 15px 0px 15px;
      h4 {
        padding: 10px 0px;
      }
    }

    .bullets-industrial-2 {
      position: relative;
      background: linear-gradient(
        180deg,
        rgba(41, 58, 100, 0),
        rgba(5, 14, 37, 0.8)
      );
      width: 90%;
      height: auto;
      top: inherit;
      right: inherit;
      padding: 15px 0px 15px;
      h4 {
        padding: 10px 0px;
      }
    }
  }
`;

const ManagementTeam = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  h2 {
    padding-bottom: 100px;
  }

  h4,
  h5 {
    font-family: KoHoMedium;
  }

  .team-1 {
    display: flex;
    /*     display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    max-width: 1200px; */
  }
  .team-2 {
    display: flex;
    flex-wrap: wrap;
    /*     display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    max-width: 950px; */
  }
  .team-3 {
    display: grid;
    flex-wrap: wrap;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    max-width: 700px;
  }
  .member {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-bottom: 70px;
    img {
      width: 169px;
      padding-bottom: 20px;
    }
  }

  @media (max-width: 758px) {
    h2 {
      font-size: 25px;
      padding-bottom: 40px;
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 11px;
    }
    /*     .team-1 {
      flex-direction: row;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: none;
      width: 265px;
      img {
        width: 62px;
        padding-bottom: 0px;
        padding-right: 40px;
      }
    }
    .team-2 {
      flex-direction: row;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: none;
      width: 265px;
      img {
        width: 62px;
        padding-bottom: 0px;
        padding-right: 40px;
      }
    } */
    .team-3 {
      flex-direction: row;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: none;
      width: 265px;
      img {
        width: 62px;
        padding-bottom: 0px;
        padding-right: 40px;
      }
    }
    .member {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      /* width: 300px; */
      padding-bottom: 40px;
      img {
        width: 62px;
        padding-bottom: 0px;
        padding-right: 40px;
      }
    }
  }
`;

const Footer = styled.section`
  background: linear-gradient(180deg, #293a64 7%, #050e25 95.31%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 276px;
  padding: 28px 8% 28px;
  .contact-social {
    display: flex;
    height: 50%;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    .logo-footer {
      width: 103px;
      padding-bottom: 20px;
    }
  }
  .contact-footer {
    display: flex;
    flex-direction: column;
  }
  .copy-terms {
    position: relative;
    width: 100%;
    text-align: center;
    a {
      text-decoration: underline;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
    p {
      font-size: 15px;
      margin: 6px 0;
      visibility: inherit !important;
      opacity: inherit !important;
    }
  }

  @media (max-width: 758px) {
    flex-direction: row;
    align-items: flex-start;
    height: auto;
    .contact-social {
      height: auto;
      width: 40px;
      margin-right: 7%;
      .logo-footer {
        width: 38px;
      }
    }
    .copy-terms {
      text-align: left;
      a {
        font-size: 11px;
      }
      p {
        font-size: 11px;
      }
    }
  }
`;

const ModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: url("/images/graficos_modal.png") no-repeat top left;
  h2 {
    padding: 20px 0px 40px;
  }
  p {
    padding-bottom: 40px;
  }
  button {
    margin-bottom: 40px;
  }
  @media (max-width: 758px) {
    background-size: 150px;
  }
  align-items: flex-start;
  justify-content: center;
  height: 250px;
`;

function App() {
  const [onTop, setOnTop] = useState("nav-desk");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      //Animaciones de Scroll
      const animatedBasics = gsap.utils.toArray(".animated");
      animatedBasics.forEach((animated) => {
        gsap.from(animated, {
          y: 50,
          autoAlpha: 0,
          duration: 1,
          scrollTrigger: {
            trigger: animated,
            toggleActions: "restart none none reset",
          },
        });
      });
    }, 700);

    setTimeout(() => {
      //Animaciones de Scroll
      const animatedRight = gsap.utils.toArray(".animated-right");
      animatedRight.forEach((animated) => {
        gsap.from(animated, {
          x: 50,
          autoAlpha: 0,
          duration: 1,
          scrollTrigger: {
            trigger: animated,
            toggleActions: "restart none none reset",
          },
        });
      });
    }, 700);

    setTimeout(() => {
      //Animaciones de Scroll
      const animatedLeft = gsap.utils.toArray(".animated-left");
      animatedLeft.forEach((animated) => {
        gsap.from(animated, {
          x: -50,
          autoAlpha: 0,
          duration: 1,
          scrollTrigger: {
            trigger: animated,
            toggleActions: "restart none none reset",
          },
        });
      });
    }, 700);

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", changeNavBar);
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  const changeNavBar = () => {
    if (window.scrollY >= 100) {
      setOnTop("nav-desk notOnTop");
    } else {
      setOnTop("nav-desk");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    window.location.reload();
  };

  const customStyles = {
    content: {
      width: "70%",
      maxWidth: "600px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      // backgroundImage: 'url("/images/graficos_modal.png") no-repeat',
      transform: "translate(-50%, -50%)",
      background: "linear-gradient(180deg, #293A64, #050E25)",
    },
  };

  return (
    <>
      <Header name="top">
        <div className={onTop}>
          <Link to="top" smooth={true} duration={1000}>
            <img src="/images/Logo1.svg" alt="Logo" className="logo" />
          </Link>
          <div className="menu desk">
            <Link
              to="about"
              className="nav-link"
              id="about-link"
              smooth={true}
              duration={1000}
              spy={true}
              offset={-150}
            >
              About
            </Link>
            <Link
              to="mission"
              className="nav-link"
              id="mission-link"
              smooth={true}
              duration={1000}
              spy={true}
              offset={-120}
            >
              Mission
            </Link>
            <Link
              to="product"
              className="nav-link"
              id="product-link"
              smooth={true}
              duration={1000}
              spy={true}
              offset={-70}
            >
              Product
            </Link>
            <Link
              to="team"
              className="nav-link"
              id="team-link"
              smooth={true}
              duration={1000}
              spy={true}
              offset={-70}
            >
              Team
            </Link>
            <Link
              to="contact"
              className="nav-link"
              id="contact-link"
              smooth={true}
              duration={1000}
              spy={true}
              offset={-70}
            >
              Contact
            </Link>
          </div>
        </div>

        <div className="menu-mobile mobile">
          <Dropdown style={{ zIndex: "10" }}>
            <DropdownTrigger>
              <img
                src="/images/dropdown-menu.svg"
                alt="Menú"
                className="mobile"
                style={{ height: "15px" }}
              />
            </DropdownTrigger>
            <DropdownContent>
              <div className="dropdown-menu">
                <ul className="mobile" style={{ padding: "0" }}>
                  <li className="mobile">
                    <Link
                      to="about"
                      id="about-link-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      About
                    </Link>
                  </li>
                  <li className="mobile">
                    <Link
                      to="mission"
                      id="mission-link-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Mission
                    </Link>
                  </li>
                  <li className="mobile">
                    <Link
                      to="product"
                      id="product-link-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Product
                    </Link>
                  </li>
                  <li className="mobile">
                    <Link
                      to="team"
                      id="team-link-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Team
                    </Link>
                  </li>
                  <li className="mobile">
                    <Link
                      to="contact"
                      id="contact-link-mobile"
                      style={{ fontSize: "16px" }}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </DropdownContent>
          </Dropdown>
        </div>

        <div className="title">
          <h1 className="animated">Dynamic</h1>
          <h1 className="animated">Reinsurance</h1>
        </div>
        <img src="/images/MexicoCity.jpg" alt="mexico-city" />
      </Header>

      <About name="about">
        <h2 className="animated">About us</h2>
        <p className="animated desk">
          Dynamic Reinsurance is an Underwriting Agency registered in the USA.{" "}
          <br />
          We provide property facultative and treaty reinsurance to brokers and
          insurance companies operating in Latin America and the Caribbean.{" "}
          <br />
          Coupled with actuarial and claims services, we provide a full set of
          reinsurance solutions.
        </p>
        <p className="animated mobile">
          We provide property facultative and treaty reinsurance to brokers and
          insurance companies operating in Latin America and the Caribbean.
        </p>
      </About>

      <Paralax className="desk" />

      <div className="mobile">
        <img src="/images/Miami2.jpg" alt="Miami" className="mobile" />
      </div>

      <MissionStatement name="mission">
        <h2 className="animated">
          Mission <br /> Statement
        </h2>
        <p className="animated desk">
          Provide quality reinsurance capacity coupled with actuarial and claims
          services to our clients and business partners, focused on flexible
          solutions, efficient response on submissions, and local market
          knowledge.
        </p>
        <p className="animated mobile">
          Provide quality reinsurance capacity to our clients and business
          partners, focused on flexible solutions, efficient response on
          submissions, and local market knowledge.
        </p>
      </MissionStatement>

      <ProductOffering name="product">
        <div className="back-transparency animated">
          <h2 className="animated">Property Facultative</h2>
        </div>
      </ProductOffering>

      <Services>
        <div className="bullets-services-1 mobile">
          <div>
            <h2 className="animated mobile" style={{ paddingBottom: "30px" }}>
              SERVICE
              <br />
              INDUSTRY
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "61%",
              paddingBottom: "15px",
            }}
          >
            <h4 className="animated mobile">Tourism</h4>
            <div className="circle animated mobile"></div>
            <h4 className="animated mobile">Education</h4>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "61%",
              paddingBottom: "15px",
            }}
          >
            <h4 className="animated mobile">Finance</h4>
            <div className="circle animated mobile"></div>
            <h4 className="animated mobile">Hospitality</h4>
          </div>
          <h4 className="animated mobile" style={{ paddingBottom: "15px" }}>
            Retail
          </h4>
        </div>

        <div
          className="mobile"
          style={{ width: "90%", justifyContent: "space-between" }}
        >
          <img
            src="/images/hotel_1-mobile.png"
            alt="Hotel"
            className="img_hotel animated"
          />
          <img
            src="/images/hospital.jpg"
            alt="Hospital"
            className="img_hospital animated"
          />
          <img
            src="/images/roadway.jpg"
            alt="Roadway"
            className="img_roadway animated"
          />
        </div>

        <div className="desk">
          <img
            src="/images/hotel_1.jpg"
            alt="Hotel"
            className="img_hotel animated-left"
          />
          <img
            src="/images/hospital.jpg"
            alt="Hospital"
            className="img_hospital animated-right"
          />
          <img
            src="/images/roadway.jpg"
            alt="Roadway"
            className="img_roadway animated-right"
          />
        </div>

        <div className="bullets-services-1 desk">
          <div style={{ position: "relative" }}>
            <h2 className="animated desk">SERVICE INDUSTRY</h2>
          </div>
          <h4 className="animated desk">Hospitality</h4>
          <div className="circle animated desk"></div>
          <h4 className="animated desk">Tourism</h4>
          <div className="circle animated desk"></div>
          <h4 className="animated desk">Finance</h4>
          <div className="circle animated desk"></div>
          <h4 className="animated desk">Education</h4>
          <div className="circle animated desk"></div>
          <h4 className="animated desk">Retail</h4>
        </div>

        <div className="bullets-services-2">
          <h4 className="animated">Real Estate</h4>
          <div className="circle animated"></div>
          <h4 className="animated">Health Sector</h4>
          <div className="circle animated"></div>
          <h4 className="animated">Transportation</h4>
          <div className="circle animated"></div>
          <h4 className="animated">Telecommunications</h4>
        </div>
      </Services>

      <Industrial>
        <div className="bullets-industrial-1 mobile">
          <div>
            <h2 className="animated mobile" style={{ paddingBottom: "30px" }}>
              INDUSTRIAL FACILITIES
            </h2>
          </div>
          <h4 className="animated mobile">Hydroelectric</h4>
          <div className="circle animated mobile"></div>
          <h4 className="animated mobile">Thermoelectric</h4>
          <div className="circle animated mobile"></div>
          <h4 className="animated mobile">Metal Processing</h4>
          <div className="circle animated mobile"></div>
          <h4 className="animated mobile">Cement Manufacturing</h4>
        </div>

        <div
          className="mobile"
          style={{ width: "90%", justifyContent: "space-between" }}
        >
          <img
            src="/images/Planta-mobile.png"
            alt="Planta"
            className="img_planta animated"
          />
          <img
            src="/images/turbine.jpg"
            alt="Turbine"
            className="img_turbine animated"
          />
          <img
            src="/images/buildings.jpg"
            alt="Buildings"
            className="img_buildings animated"
          />
        </div>

        <div className="desk">
          <img
            src="/images/Planta.jpg"
            alt="Planta"
            className="img_planta animated-right"
          />
          <img
            src="/images/turbine.jpg"
            alt="Turbine"
            className="img_turbine animated-left"
          />
          <img
            src="/images/buildings.jpg"
            alt="Buildings"
            className="img_buildings animated-left"
          />
        </div>

        <div className="bullets-industrial-1 desk">
          <div style={{ position: "relative" }}>
            <h2 className="animated desk">INDUSTRIAL FACILITIES</h2>
          </div>
          <h4 className="animated desk">Hydroelectric</h4>
          <div className="circle animated desk"></div>
          <h4 className="animated desk">Thermoelectric</h4>
          <div className="circle animated desk"></div>
          <h4 className="animated desk">Metal Processing</h4>
          <div className="circle animated desk"></div>
          <h4 className="animated desk">Cement Manufacturing</h4>
        </div>

        <div className="bullets-industrial-2">
          <h4 className="animated">Water Treatment</h4>
          <div className="circle animated"></div>
          <h4 className="animated">Eolic / Solar</h4>
          <div className="circle animated"></div>
          <h4 className="animated">Pharmaceutical</h4>
          <div className="circle animated"></div>
          <h4 className="animated">Food & Beverage</h4>
          <div className="circle animated"></div>
          <h4 className="animated">Glassworks & Glass Products</h4>
        </div>
      </Industrial>

      <ManagementTeam name="team">
        <div className="line animated" style={{ marginBottom: "7vw" }}></div>
        <h2 className="animated">Management Team</h2>
        <div className="team-1">
          <div className="member">
            <img
              className="animated"
              src="/images/Francisco Hernandez.png"
              alt="Francisco Hernández"
            />
            <div>
              <h4 className="animated">Francisco Hernández</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                CEO
              </h5>
            </div>
          </div>
          <div className="member">
            <img
              className="animated"
              src="/images/Lalo-Betancourt.png"
              alt="Eduardo Betancourt"
            />
            <div>
              <h4 className="animated">Eduardo Betancourt</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                CUO/COO
              </h5>
            </div>
          </div>
          <div className="member">
            <img
              className="animated"
              src="/images/Adriana Perez.png"
              alt="Adriana Pérez"
            />
            <div>
              <h4 className="animated">Adriana Pérez</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                Head of Casualty and FL
              </h5>
            </div>
          </div>
          <div className="member">
            <img
              className="animated"
              src="/images/Lorena Arciniegas.png"
              alt="Lorena Arciniegas"
            />
            <div>
              <h4 className="animated">Lorena Arciniegas</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                Head Miami Office
              </h5>
            </div>
          </div>{" "}
          <div className="member">
            <img
              className="animated"
              src="/images/Jocelyn Naranjo.png"
              alt="Jocelyn Naranjo"
            />
            <div>
              <h4 className="animated">Jocelyn Naranjo</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                Senior Underwriter
              </h5>
            </div>
          </div>
        </div>

        <div className="team-2">
          <div className="member">
            <img
              className="animated"
              src="/images/Jose Luis Hernandez.png"
              alt="Jose Luis Hernández"
            />
            <div>
              <h4 className="animated">Jose Luis Hernández</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                Underwriter and Cat Modeler
              </h5>
            </div>
          </div>
          <div className="member">
            <img
              className="animated"
              src="/images/Dulce Jardon.png"
              alt="Dulce Jardón"
            />
            <div>
              <h4 className="animated">Dulce Jardón</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                Claims Manager
              </h5>
            </div>
          </div>
        </div>
        <div className="team-3">
          <div className="member">
            <img
              className="animated"
              src="/images/Valeria Sanchez.png"
              alt="Valeria Sanchez"
            />
            <div>
              <h4 className="animated">Valeria Sanchez</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                Technical Assistant
              </h5>
            </div>
          </div>{" "}
          <div className="member">
            <img
              className="animated"
              src="/images/Camila Villa.png"
              alt="Camila Villa"
            />
            <div>
              <h4 className="animated">Camila Villa</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                Technical Assistant
              </h5>
            </div>
          </div>{" "}
          <div className="member">
            <img
              className="animated"
              src="/images/Pablo Munoz.png"
              alt="Pablo Muñóz"
            />
            <div>
              <h4 className="animated">Pablo Muñóz</h4>
              <h5 className="animated" style={{ color: "#1E9FE6 " }}>
                Claims Executive
              </h5>
            </div>
          </div>
        </div>
        <div className="line animated" style={{ marginTop: "4vw" }}></div>
      </ManagementTeam>

      <Contact name="contact" setIsModalVisible={setIsModalVisible} />

      <Footer>
        <div className="contact-social">
          <img
            src="/images/Logo1.svg"
            alt="Logo"
            className="logo-footer animated"
          />
        </div>

        <div className="copy-terms animated">
          <NavLink to="/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policies
          </NavLink>
          <p style={{ color: "rgba(255,255,255,0.5)" }}>
            © Dynamic Reinsurance {new Date().getFullYear()}. All Rights
            Reserved
          </p>
        </div>
      </Footer>

      <Modal
        isOpen={isModalVisible}
        onRequestClose={handleCancel}
        style={customStyles}
      >
        <ModalStyle>
          <h2>Thanks for contacting us!</h2>
          <p style={{ textAlign: "center" }}>
            We’ve received your information and <br />
            you’ll hear from us very soon
          </p>
          <button
            onClick={handleCancel}
            style={{
              maxWidth: "283px",
              color: "white",
              width: "100%",
              height: "36px",
            }}
          >
            Back home
          </button>
        </ModalStyle>
      </Modal>
    </>
  );
}

export default App;
