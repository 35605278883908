import Api from "../axios-setup";
// eslint-disable-next-line no-unused-vars
import { COCKTAIL_PATHS, AREACODE_PATH } from "../paths-services/cocktail-paths";

class CocktailServices {

  async saveInfo(dataObj) {
    try {
      const { data } = await Api.post(`${COCKTAIL_PATHS.CREATE}`, {
        ...dataObj
      })

      return data
    } catch (error) {
      throw error
    }
  }

  async getAreacodeCatalog() {
    try {
       const { data } = await Api.get(AREACODE_PATH.GET_AREACODE_CATALOG)

      // const data = [
      //   {
      //     id: 1,
      //     name: 'Mexico',
      //     currency: 'MNX',
      //     shortName: 'MX',
      //     suffix: '52'
      //   },
      //   {
      //     id: 1,
      //     name: 'Estados Unidos',
      //     currency: 'USD',
      //     shortName: 'USA',
      //     suffix: '123'
      //   }
      // ]

      return data
    } catch (error) {
      throw error
    }
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CocktailServices()
