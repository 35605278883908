import { useEffect, useState } from 'react'
import CocktailServices from '../../services/cocktail/cocktail.services'

export const useGetAreacodeCountries = () => {
  const [countries, setCountry] = useState([{}])

  const getAreacodeCoutries = async () => {
    const data = await CocktailServices.getAreacodeCatalog()
    setCountry(data)
  }

  useEffect(() => {
    /* CountryService.getAll()
      .then(countries => {
        setCountry(countries)
      })
      .catch(error => {
        throw error
      }) */
      getAreacodeCoutries()
  }, [])

  return {
    countries,
    getAreacodeCoutries
  }
}
