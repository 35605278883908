import { useState, useEffect } from 'react'
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import emailjs from 'emailjs-com'

const Contact = styled.section`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
.contact-form {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 7vw 0px;
  form {
    padding-left: 190px;
    .two-inputs {
      display: flex;
    }
    .checkbox {
      display: flex;
      justify-content: baseline;
      align-items: center;
      padding: 30px 0px 0px 20px;
      input {
        -webkit-appearance: none;
        width: 18px;
        height: 19px;
        margin: 0px 20px 0px 0px;
        background-color: rgba(0,0,0,0);
        border: 2px solid rgba(255,255,255,0.3);
        border-radius: 2px;
      }
      input:checked {
        background-color: #3BACF2;
      }
      p {
        margin: 0px;
        opacity: 0.3;
      }
    }
    button {
      width: 691px;
      margin: 40px 0px 0px 0px;
    }
  }
}
.director {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 169px;
    padding-right: 70px;
  }
  h4 {
    font-size: 30px;
  }
  padding-bottom: 7vw;
}
.director-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: left;
  justify-content: center;
  p {
    margin: 10px 0px;
  }
  .contact-title {
    padding: 0px 0px 20px 29px;
  }
  .email {
    display: flex;
    align-items: center;
    padding-top: 15px;
    img {
      width: 21px;
      padding: 0 7px 0 0;
    }
  }
}
.linkedin-director {
  display: flex;
  align-items: center;
  h4 {
    cursor: pointer;
  }
  img {
    height: 21px;
    width: 21px;
    padding-right: 10px;
    cursor: pointer;
  }
}

@media (max-width: 758px) {
  h2 {
    font-size: 25px;
    padding-bottom: 25px;
  }
  .contact-form {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0px 0px;
      .checkbox {
        padding: 30px 10px 10px;
      }
      .two-inputs {
        flex-wrap: wrap;
        justify-content: center;
      }
      button {
        width: 90%;
        margin: 40px 0px 15px 0px;
      }
    }
  }
  .director {
    img {
      width: 62px;
      padding-right: 0px;
    }
    h4 {
      font-size: 18px;
    }
    padding-bottom: 7vw;
  }
  .director-info {
    flex-direction: column;
    align-items: center;
    .contact-title {
      font-size: 25px;
      padding: 0px 0px 15px 0px;
    }
    .email {
      img {
        width: 11px;
      }
    }
  }
  .linkedin-director {
    img {
      width: 11px;
      height: 11px;
      padding-right: 5px;
    }
  }
}
`

const CREATE_CONTAC = gql`
mutation CreateContac($input: ContactInput) {
  createContac(input: $input) {
    statusCode
    message
    error
  }
}
`;

export const ContactSection = ({name, setIsModalVisible}) => {

  useEffect(() => {
    checkDisabled()
  })

  const [createContac] = useMutation(CREATE_CONTAC, {
    onCompleted( response ) {
      //console.log(response)
    },
    onError( errorCeateContac ) {
      //console.log(errorCeateContac)
    }
  });

  const [check, setCheck] = useState(false);
  const [disabled, setDisabled] = useState(true)
  const [form, setForm] = useState({
    full_name: "",
    email: "",
    country: "", 
    phone: "", 
    city: "",
    message: "",
  })

  let input = {
    name: form.full_name,
    email: form.email,
    country: form.country,
    phone: form.phone,
    city: form.city,
    message: form.message
  }

  const allowLetters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ ]/g, '')
  }

  const wordsToUppercase = (e) => {
    e.target.value = e.target.value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  const handleChange = (e) => {
    setForm({
        ...form,
        [e.target.name]: e.target.value,
    })
  }
  
  const checker = () => {
    if (check) {
      setCheck(false)
    } else {
      setCheck(true)
    }
  };
  
  const checkDisabled = () => {
    if ((form.email.length !== "" && form.email.length !== 0) && 
    (form.full_name.length !== "" && form.full_name.length !== 0) &&  
    (form.phone.length !== "" && form.phone.length !== 0) &&
    (form.country.length !== "" && form.country.length !== 0) &&
    (form.city.length !== "" && form.city.length !== 0) &&
    (form.message.length !== "" && form.message.length !== 0) &&
    (check)) {
    setDisabled(false);
    }
    else {
      setDisabled(true);
    }
  }

  const sendForm = e => {
    e.preventDefault(); 

    let template_params = input;
    const service_id = "service_kcxnvgi";
    let user_id="user_ObEBFw7N5Ho6NafC77T9S";
    const template_id = "template_li9np3g";
    emailjs.send(service_id, template_id, template_params,user_id)
    .then((response) => {
      console.log("SUCCESS!", response.status, response.text);
    }, (err) => {
      console.log("FAILED...", err);
    });

    createContac({ variables: { input: input } })
    setIsModalVisible(true)
  }

  return (
    <Contact name={name}>
      <div className="contact-form">
        <h2 className='animated' >Contact us</h2>

        <form onSubmit={sendForm} className='animated' id="form">
          <input type="text" placeholder='Full Name' name='full_name' value={form.full_name} onChange={handleChange} onInput={(e) => {allowLetters(e); wordsToUppercase(e)}} maxLength={40}/>
          <div className='two-inputs'>
            <input type="email" placeholder='E-mail' name='email' value={form.email} onChange={handleChange} maxLength={40}/>
            <input type="text" placeholder='Country' name='country' value={form.country} onChange={handleChange} onInput={(e) => {allowLetters(e); wordsToUppercase(e)}} maxLength={30}/>
          </div>
          <div className='two-inputs'>
            <input type="text" placeholder='City' name='city' value={form.city} onChange={handleChange} onInput={(e) => {allowLetters(e); wordsToUppercase(e)}} maxLength={40} className='mobile' />
            <input type="number" placeholder='Phone' name='phone' value={form.phone} onChange={handleChange} maxLength={20} />
            <input type="text" placeholder='City' name='city' value={form.city} onChange={handleChange} onInput={(e) => {allowLetters(e); wordsToUppercase(e)}} maxLength={40} className='desk' />
          </div>
          <textarea type="multiline" placeholder='Message' name='message' value={form.message} onChange={handleChange} maxLength={250}/>
          <div className='checkbox' >
            <input type="checkbox" checked={check} onChange={checker} name="checkbox" />
            <p>I´ve read and agree with the privacy policy</p>
          </div>
          <button style={{  width: '100%', height: '36px' }}type="submit" disabled={disabled}>Submit</button>
        </form>
        
      </div>
      <div className="line animated" style={{marginBottom: '7vw'}}></div>
      {/* <div className="director">
        <img className='animated desk' src="/images/Francisco.png" alt="Director" />
        <div className="director-info">
          <h4 className='animated contact-title' >Contact</h4>
          <img className='animated mobile' src="/images/Francisco.png" alt="Director" style={{marginBottom: '10px'}} />
          <div className='linkedin-director'>
            <a href="https://www.linkedin.com/in/francisco-hernandez-188720" target="_blank" rel="noreferrer" >
              <img className='animated' src="/images/linkedin-blue.svg" alt="Linkedin" />
            </a>
            <a href="https://www.linkedin.com/in/francisco-hernandez-188720" target="_blank" rel="noreferrer" >
              <h4 className='animated' >Francisco Hernández</h4>
            </a>
          </div>
          <h4 className='animated' style={{color: '#1E9FE6', paddingLeft: '29px'}}>Managing Director</h4>
          <div className='email'>
            <img className='animated' src="/images/Envelope.svg" alt="Sobre" />
            <a className='animated' href="https://mail.google.com/mail/?view=cm&fs=1&to=francisco@dynamicinsurance.com" target="_blank" rel="noreferrer" style={{textDecoration: 'underline', cursor: 'pointer'}} >info@dynamicreinsurance.com</a>
          </div>
        </div>
      </div> */}
    </Contact>
  )
}
