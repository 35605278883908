import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom"
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown'

const PrivacyPolicies = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: auto;
padding: 200px 200px 150px;
background: url("/images/fondo_privacy.png") no-repeat top right;
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
h2 {
  font-family: HindVadodara;
  font-size: 65px;
  font-weight: 700;
  padding-bottom: 90px;
}
h3 {
  font-family: KoHoRegular;
  font-size: 36px;
  line-height: 46px;
  max-width: 840px;
}
h4 {
  font-family: KoHoBold;
  font-size: 30px;
  line-height: 39px;
}
h5 {
  font-family: KoHoBold;
  font-size: 24px;
  line-height: 32px;
}
p {
  font-family: KoHoRegular;
  font-size: 24px;
  line-height: 32px;
}
ul {
  margin: 0;
}
a {
  text-decoration: underline;
  color: #3BACF2;
}
@media (max-width: 758px) {
  padding: 100px 30px 50px;
  background-size: 300px;
  h2 {
    font-size: 34px;
    padding-bottom: 40px;
    line-height: 34px;
  }
  h3 {
    font-size: 20px;
    line-height: 30px;
  }
  h4 {
    font-size: 18px;
    line-height: 23px;
    padding-bottom: 5px;
  }
  h5 {
    font-size: 16px;
    line-height: 20px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
  }
}
`
const Header = styled.header`
position: relative;
background-color: #293A64;
.title {
  top: 62%;
  left: 17%;
  position: absolute;
  h1:nth-child(2) {
    font-size: 90px;
  }
}
.nav-desk {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50px;
  height: 62px;
  width: 100%;
  background-color: rgba(196, 196, 196, 0.2);
  z-index: 9;
  transition: top 0.5s ease-in-out, background-color 1s ease-in-out;
  .logo {
    height: 50px;
    width: min-content;
    margin-left: 56px;
    cursor: pointer;
  }
  .nav-desk.active {
    top: 0px;
  }
  .menu {
    margin-right: 10%;
  }
  .nav-link {
    font-family: KoHoRegular;
    font-weight: 400;
    font-size: 20px;
    margin: 0 20px;
    cursor: pointer;
  }
  &.notOnTop {
    top: 0px;
    background-color: rgba(196, 196, 196, 0.4);
    transition: top 0.5s ease-in-out, background-color 1s ease-in-out;
  }
}
  @media (max-width: 758px) {
    .title {
      h1:nth-child(2) {
        font-size: 36px;
      }
    }
    .nav-desk {
      top: 0px;
      height: 52px;
      background-color: #0E1D39;
      .logo {
        height: 25px;
        margin-left: 30px;
      }
      .menu {
        margin-right: 10%;
      }
      &.notOnTop {
        top: 0px;
        background-color: #0E1D39;
        transition: top 0s ease-in-out, background-color 0s ease-in-out;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-left: 0;
      align-items: flex-end;
    }
    li {
      padding: 0px 24px 16px;
    }
    .menu-mobile {
      display: flex;
      position: fixed;
      top: 16px;
      right: 16px;
      z-index: 10;
      align-items: flex-end;
      img {
        cursor: pointer;
      }
    }
    .dropdown-menu {
      width: 100%;
      background: linear-gradient(180deg, #0E1D39, #293A64);
      padding: 20px 0 4px;
    }
  }
`
const Footer = styled.section`
background: linear-gradient(180deg, #293A64 7%, #050E25 95.31%);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 276px;
padding: 28px 8% 28px;
.contact-social {
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  .logo-footer {
    width: 103px;
    padding-bottom: 20px;
  }
}
.contact-footer {
  display: flex;
  flex-direction: column;
}
.copy-terms {
  position: relative;
  width: 100%;
  text-align: center;
  a {
    text-decoration: underline;
    color: rgba(255,255,255,0.8);
    cursor: pointer;
  }
  p {
    font-size: 15px;
    margin: 6px 0;
    visibility: inherit !important;
    opacity: inherit !important;
  }
}

@media (max-width: 758px) {
  flex-direction: row;
  align-items: flex-start;
  height: auto;
  .contact-social {
    height: auto;
    width: 40px;
    margin-right: 7%;
    .logo-footer {
      width: 38px;
    }
  }
  .copy-terms {
    text-align: left;
    a {
      font-size: 11px;
    }
    p {
      font-size: 11px;
    }
  }
}
`

function Privacy() {
  const [onTop, setOnTop] = useState('nav-desk');

  useEffect(() => {
    window.addEventListener('scroll', changeNavBar)
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, [])
  
  const changeNavBar = () => {
    if (window.scrollY >= 100) {
      setOnTop('nav-desk notOnTop')
    } else {
      setOnTop('nav-desk')
    }
  }

  const showSection = (id) => {
    setTimeout(() => {
      document.getElementById(id).click()
    }, 300);
  }

  return (
  <>
    <Header name='top'>
      <div className={onTop}>
        <NavLink to='/' smooth={true} duration={1000}  >
          <img src="/images/Logo1.svg" alt="Logo" className='logo' />
        </NavLink>
        <div className='menu desk'>
          <NavLink 
            to='/'
            className='nav-link'
            onClick={() => showSection('about-link')}
          >
            About
          </NavLink>
          <NavLink 
            to='/'
            className='nav-link'
            onClick={() => showSection('mission-link')}
          >
            Mission
          </NavLink>
          <NavLink 
            to='/'
            className='nav-link'
            onClick={() => showSection('product-link')}
          >
            Product
          </NavLink>
          <NavLink 
            to='/'
            className='nav-link'
            onClick={() => showSection('team-link')}
          >
            Team
          </NavLink>
          <NavLink 
            to='/'
            className='nav-link'
            onClick={() => showSection('contact-link')}
          >
            Contact
          </NavLink>
        </div>
      </div>

      <div className='menu-mobile mobile'>
        <Dropdown style={{zIndex: '10'}}>
          <DropdownTrigger>
              <img src='/images/dropdown-menu.svg' alt="Menú" className='mobile' style={{height: '15px'}} />
          </DropdownTrigger>
          <DropdownContent>
            <div className='dropdown-menu'>
              <ul className='mobile' style={{padding: '0'}}>
                <li className='mobile'><NavLink to="/" onClick={() => showSection('about-link-mobile')} style={{fontSize: '16px'}}>About</NavLink></li>
                <li className='mobile'><NavLink to="/" onClick={() => showSection('mission-link-mobile')} style={{fontSize: '16px'}}>Mission</NavLink></li>
                <li className='mobile'><NavLink to="/" onClick={() => showSection('product-link-mobile')} style={{fontSize: '16px'}}>Product</NavLink></li>
                <li className='mobile'><NavLink to="/" onClick={() => showSection('team-link-mobile')} style={{fontSize: '16px'}}>Team</NavLink></li>
                <li className='mobile'><NavLink to="/" onClick={() => showSection('contact-link-mobile')} style={{fontSize: '16px'}}>Contact</NavLink></li>
              </ul>
            </div>
          </DropdownContent>
        </Dropdown>
      </div>
    </Header>
    <PrivacyPolicies>
      <h2>Política de Privacidad</h2>
      <div className='content'>
        <h3>Dynamic Reinsurance LLC, en adelante DYNAMIC RE, con domicilio en 1395 Brickell Ave Suite 800 Miami, FL 33131 es titular de este sitio Web, y responsable de los datos de carácter personal suministrados por los usuarios a través de este sitio Web.</h3>
        <p>
          El usuario queda informado sobre el tratamiento de los datos suministrados durante la navegación en la página Web y los que se generen como consecuencia de la utilización de la misma, incluidas, en su caso, las comunicaciones o las transferencias internacionales de los datos que pudieran realizarse, con las finalidades indicadas en el apartado ¿Con qué finalidad tratamos sus datos personales?
          <br /><br />
          En caso de que los datos facilitados se refieran a terceras personas físicas distintas del usuario, éste garantiza haber recabado y contar con el consentimiento previo de los mismos para la comunicación de sus datos y haberles informado, con carácter previo a facilitarlos, de las finalidades del tratamiento, comunicaciones y demás términos previstos en el apartado Información sobre Protección de Datos.
          <br /><br />
          El usuario garantiza la exactitud y veracidad de los datos facilitados, adquiriendo el compromiso de comunicar a DYNAMIC RE cualquier cambio en los mismos.
          <br /><br />
          La utilización de esta web está sujeta a la Política de Privacidad y Tratamiento de Datos Personales, a las Condiciones de Uso que se detallan a continuación, así como a la Política de Cookies. Te rogamos que las leas atentamente.
          <br /><br />
          <h4>INFORMACIÓN SOBRE PROTECCIÓN DE DATOS</h4>
          <h5>¿Quién es el responsable del tratamiento de sus datos personales?</h5>
          El responsable del tratamiento de la información y/o datos personales que nos proporcione, es:
          <br /><br />
          Identidad: Dynamic Reinsurance LLC, EIN 87-1378924 <br />
          Dirección postal: 1395 Brickell Ave Suite 800 Miami, FL 33131 
          <br /><br />
          <h5>¿Qué datos recabamos?</h5> <br />
          DYNAMIC RE obtiene información acerca de sus usuarios. Los datos que se conservan son:
          <br /><br />
          <ul>
            <li>
              Los datos obtenidos de la navegación y uso de nuestro sitio web, en concreto:
            </li>
            <li>
              El nombre de dominio del proveedor (ISP) que le da acceso a la red. Por ejemplo, un usuario del proveedor X estará identificado con el dominio X.es.
            </li>
            <li>
              La fecha y hora de acceso a nuestra web.
            </li>
            <li>
              La dirección de internet desde la que partió el link que dirige a nuestra web.
            </li>
            <li>
              Los datos identificativos, económicos, y cualquier otro que nos facilite, a través de cada uno de los formularios accesibles por el usuario en el Sitio Web y con la finalidad expuesta en cada uno de los formularios.
            </li>
          </ul>
          <br />
          <h5>¿Con qué finalidad tratamos sus datos personales?</h5><br />
          A continuación, se detallan las finalidades de los tratamientos:
          <br /><br />
          <ul>
            <li>
              Respecto a los datos obtenidos en la navegación y uso de nuestro sitio web, realizaremos el tratamiento de los datos generados por la navegación con las finalidades en base a lo establecidas en el panel de cookies y sus preferencias asociadas Configurar cookies.
            </li>
            <li>              
              El portal del que es titular DYNAMIC RE contiene enlaces a sitios web de terceros, cuyas políticas de privacidad son ajenas a la de DYNAMIC RE.  Al acceder a tales sitios web usted puede decidir si acepta sus políticas de privacidad y de cookies. Con carácter general, si navega por internet usted puede aceptar o rechazar las cookies de terceros desde las opciones de configuración de su navegador.
            </li>
            <li>              
              En su caso, los datos facilitados en los formularios accesibles en el Sitio Web serán tratados con las finalidades relacionadas con dichos formularios, en concreto:
            </li>
            <li>              
              En el caso de que se ponga en contacto con nosotros a través de cualquiera de las direcciones publicadas, trataremos sus datos para ponernos en contacto con Ud. y atender su petición.
            </li>
            <li>              
              En caso de facilitar sus datos personales para gestionar su solicitud de alta, registro como usuario, y/o reseteo de contraseña, trataremos sus datos para ponernos en contacto con Ud. y atender su petición.
            </li>
          </ul>
          <br />
          <h5>¿Por cuánto tiempo conservaremos sus datos personales?</h5><br />
          Los datos personales proporcionados se conservarán por el plazo determinado en base los siguientes criterios: (i) obligación legal de conservación; y, (ii) solicitud de supresión por parte del interesado en los supuestos en los que proceda.
          <br /><br />
          <h5>¿Cuál es la legitimación para el tratamiento de sus datos?</h5><br />
          La base legal para el tratamiento de sus datos con las finalidades incluidas en el apartado “¿Con qué finalidad tratamos sus datos personales?” se detallan a continuación:
          <br /><br />
          <ul>
            <li>              
              Respecto a la navegación y uso de nuestro sitio web, es el consentimiento que haya otorgado el usuario en el panel de cookies Configurar cookies. <br />
            </li>
            <li>              
              Respecto al tratamiento de los datos de los correos que remita a cualquiera de las direcciones publicadas, la base legitimadora es el consentimiento del usuario al facilitar sus datos como contacto para atender su petición.
            </li>
            <li>              
              Respecto al tratamiento de los datos para gestionar su solicitud de alta, registro o reseteo de contraseña, como usuario, la base legitimadora es el consentimiento del usuario al facilitar sus datos para atender su petición.
            </li>
          </ul>
          <br />
          <h5>¿A qué destinatarios se comunicarán sus datos?</h5><br />
          DYNAMIC RE podrá comunicar sus datos exclusivamente para las finalidades indicadas en el apartado “¿Con qué finalidad tratamos sus datos personales?”, a los siguientes destinatarios:
          <br /><br />
          <ul>
            <li>
              A las distintas entidades de prestación de servicios de DYNAMIC RE para la adecuada prestación del servicio. 
            </li> 
            <li>
              A autoridades, reguladores u órganos gubernamentales en aquellos supuestos exigidos por la ley, la normativa local o en cumplimiento de obligaciones regulatorias.
            </li>
            <li>
              A terceras empresas con las que se llegue a acuerdos de colaboración para la prestación de servicios cualificados solicitados por el cliente.
            </li>
            <li>
              A entidades reaseguradoras necesarias para el cumplimiento del contrato.
            </li>
            <li>
              A las distintas entidades aseguradoras, financieras, inmobiliarias, filiales y participadas con motivo de la gestión integral y centralizada de la relación de los    interesados con DYNAMIC RE.
            </li>
            <li>
              En el marco de dichas comunicaciones, podrán realizarse transferencias internacionales de datos a terceros países. Dichas transferencias se realizarán a países donde no exista una decisión de adecuación de la Comisión Europea únicamente cuando sean imprescindibles para el cumplimiento de las finalidades indicadas.
            </li>
          </ul>
          <br />
          <h5>¿Cuáles son sus derechos cuando nos facilita sus datos?</h5><br />
          En los términos y con el alcance establecido en la normativa vigente, cualquier persona puede ejercer los siguientes derechos:
          <br /><br />
          <ul>
            <li>
              Acceso: Conocer qué datos personales suyos tiene DYNAMIC RE.
            </li>
            <li>
              Rectificación: Solicitar la rectificación de sus datos inexactos.
            </li>
            <li>
              Supresión: Solicitar la supresión de sus datos cuando, entre otros motivos, ya no sean necesarios para los fines para los que fueron recogidos.
            </li>
            <li>
              Limitación del tratamiento: Solicitar que suspendamos el tratamiento de sus datos, si, por ejemplo, los datos son inexactos o el tratamiento es ilícito, pudiendo no obstante ser tratados para el ejercicio o la defensa de posibles reclamaciones, la protección de los derechos de otra persona o por razones de interés público.
            </li>
            <li>
              Oposición: Oponerse al tratamiento de sus datos, salvo cuando sean necesarios para, entre otros motivos, el desarrollo de la relación contractual, en caso de que la hubiera, o para el ejercicio o la defensa de posibles reclamaciones.
            </li>
            <li>
              Portabilidad: Recibir sus datos personales en un formato estructurado, de uso común y legible, o solicitar su transmisión a otro responsable cuando sea técnicamente posible.
            </li>
            <li>
              Le recordamos que también puede, en cualquier momento, revocar el consentimiento prestado para el tratamiento de sus datos, en su caso.
            </li>
          </ul>
          <br />
          Los anteriores derechos pueden ejercerse directamente por el titular de los datos o mediante representante legal o voluntario, a través de comunicación escrita y dirigida a la Oficina Corporativa de Privacidad y Protección de Datos. 1395 Brickell Ave Suite 800 Miami, FL 33131, <a href="https://mail.google.com/mail/?view=cm&fs=1&to=francisco@dynamicinsurance.com" target='_blank' rel="noreferrer">operations@dynamicreinsurance.com</a>.
          <br /><br />
          Adicionalmente, le informamos de que puede presentar una reclamación ante la Agencia Española de Protección de Datos, cuando considere que no hemos tratado sus datos de acuerdo con la normativa, a través de la página web habilitada a tales efectos por la Autoridad de Control correspondiente.
          <br /><br />
          <h4>RESPONSABILIDADES</h4>
          Dynamic RE declina cualquier responsabilidad respecto a la información de esta web procedente de fuentes ajenas a Dynamic RE, así como de los contenidos no elaborados por la misma.
          <br /><br />
          <h4>AVISO LEGAL</h4>
          El titular del dominio es Dynamic Reinsurance LLC.
          <br /><br />
          <h4>CONDICIONES DE USO DEL SITIO WEB</h4>
          El usuario se obliga a hacer un buen uso del sitio Web, entendiéndose por buen uso el que sea conforme con la legislación vigente, buena fe y orden público.
          <br /><br />
          A su vez, el usuario se compromete a no usar el sitio Web con fines fraudulentos, así como a no realizar acto alguno con objeto de dañar, inutilizar o sobrecargar el sitio Web, o que impidiera, de cualquier forma, la normal utilización y funcionamiento del mismo.
          <br /><br />
          El usuario queda informado de que, en el caso de incumplimiento de las Condiciones de Uso, de la Política de Privacidad o de cualquier otros términos o condiciones recogidos en el sitio Web, DYNAMIC RE se reserva el derecho a limitar, suspender o bloquear su acceso al sitio Web, a través de cualquier medida técnica que considere necesaria para conseguirlo.
          <br /><br />
          <h4>COPYRIGHT</h4>
          Reservados todos los derechos. El diseño del portal y sus códigos fuente, así como los logos, marcas y demás signos distintivos que aparecen en el mismo pertenecen a DYNAMIC RE y están protegidos por los correspondientes derechos de propiedad intelectual e industrial.
          <br /><br />
          Queda rigurosamente prohibida, sin la autorización escrita de los titulares del copyright, la reproducción distribución, transformación, manipulación, comunicación pública o cualquier otro acto de explotación total o parcial, gratuito u oneroso de los textos, imágenes o cualquier otro contenido que aparezca en el sitio web.
          <br /><br />
          DYNAMIC RE se reserva el derecho de efectuar, en cualquier momento y sin previo aviso, cuantas modificaciones, variaciones, supresiones o cancelaciones en los contenidos y en la forma de presentación de estos consideren necesarias, ya sea de forma temporal o definitiva, debiendo asegurarse el usuario de utilizar en cada momento la versión actualizada. Esta facultad no otorga a los usuarios ningún derecho a percibir indemnización por daños y perjuicios.
          <br /><br />
          El presente aviso se entrega en cumplimiento a las disposiciones aplicables de la Ley Federal de Protección de Datos personales en posesión de Particulares ("Ley de Datos Personales").
          <br /><br />
          <h4>DISCLAIMER</h4>
          La información mostrada en este correo y sus archivos anexos es responsabilidad única del remitente y la operadora de servicios con la cual usted ha contactado.
          <br /><br />
          DYNAMIC REINSURANCE no aceptan ninguna responsabilidad expresa o tácita por el uso inapropiado de la información dispuesta en este correo; el destinatario es el último responsable de los daños eventuales que por mal uso haya dado a la información. Al haber recibido este correo y reconocerse como destinatario del contenido en él mostrado, habrá aceptado los términos aquí descritos
          <br /><br />
          AVISO DE CONFIDENCIALIDAD La Información contenida en este correo y sus archivos anexos es Restringida, Confidencial y Privilegiada de DYNAMIC REINSURANCE. El uso, distribución o copiado de este mensaje o cualquier información adjunta esta estrictamente prohibido, tampoco podrá revelar su contenido a ninguna persona. Si recibe este e-mail por error, favor de eliminarlo permanentemente y notificar a DYNAMIC REINSURANCE inmediatamente al correo electrónico: <br />
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=francisco@dynamicinsurance.com" target='_blank' rel="noreferrer">operations@dynamicreinsurance.com</a>.
          <br /><br />
          El uso no autorizado de este mensaje es considerado como un DELITO.
        </p>
      </div>
      <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center'}}>
        <p 
          style={{textDecoration: 'underline', color: ' #3BACF2', cursor: 'pointer'}}
          onClick={() => window.scrollTo(0, 0)}
        >Volver arriba</p>
      </div>
    </PrivacyPolicies>
    <Footer>
      <div className="contact-social">
        <img src="/images/Logo1.svg" alt="Logo" className='logo-footer' />
      </div>

      <div className="copy-terms">
        <NavLink to='/privacy' target="_blank" rel="noopener noreferrer" >Privacy Policies</NavLink>
        <p style={{color: 'rgba(255,255,255,0.5)'}}>© Dynamic Reinsurance {new Date().getFullYear()}.All Rights Reserved</p>
      </div>
    </Footer>
  </>
  )
}

export default Privacy
