// ** React Imports
import { useState } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loginAnimation from "../utils/animations/login-animation.json";

// ** MUI Components
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";

// ** Third Party Imports
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

// ** Layout Import
import { CircularProgress } from "@mui/material";
import CountrySelect from "../components/CountrySelect";
import CustomAlert from "../components/CustomAlert";
import CocktailServices from "../services/cocktail/cocktail.services";

//**  */


const delayMs = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const Background = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loginAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="background">
      <div>
        <Lottie options={defaultOptions} />
      </div>
    </div>
  );
};

const schema = yup.object().shape({

  name: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'Enter a valid name')
    .min(3, 'Enter a valid name with a minimum of 3 characters')
    .required('Required'),
  company: yup
    .string()
    .min(2, 'Enter a valid company name with a minimum of 2 characters')
    .required('Required '),
  email: yup
    .string()
    .email("Ented valid e-mail, example: name@mail.com")
    .matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$', 'Ented valid e-mail, example: name@mail.com')
    .required("Enter a valid email, example: name@email.com"),
  phone: yup
    .string()
    .matches(/^\d{7,10}$/, 'Enter a valid phone number containing 10 digits')
    .required('Required'),
  role: yup
    .string()
    .min(2, 'Enter a valid role name with a minimum of 2 characters')
    .required('Required'),
});

const DynamicCocktail = () => {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)
  const [submitDisable, setSubmitDisable]= useState(false)  
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [badgeData, setBadgeData] = useState({
    message: "",
    status: undefined,
    disableAutoHide: true,
  });
  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleNameChange = (event) => {
    const filteredValue = event.target.value.replace(/[^A-Za-z\s]/g, '');
    event.target.value = filteredValue;
    return filteredValue;
  };

  const onSubmit = async (data) => {

    const { name, company, email, phone, role } = data

    const submitData = {
      name: name,
      company: company,
      email: email,
      phone: selectedCountry.suffix + phone,
      role: role,
    }

    try {
      setSubmitDisable(true)
      const response = await CocktailServices.saveInfo(submitData)


      if (response.statusCode == 200) {
        console.log('response', response)
        setBadgeData({
          message: `Thank you! Your form has been submitted.`,
          status: 'success',
          open: true,
          theme: 'success',
          disableAutoHide: true
        })
      } else {
        setBadgeData({
          message: response.statusCode == 404 ? 'This user is already registered': `The information is incomplete or invalid.`,
          theme: 'error',
          open: true,
          status: "error",
          disableAutoHide: true,
        });
        setSubmitDisable(false)
      }
    } catch (error) {
      setBadgeData({
        message: `The information is incomplete or invalid.`,
        theme: 'error',
        open: true,
        status: "error",
        disableAutoHide: true,
      });
      setSubmitDisable(false)
    }
    await delayMs(4000)

    setBadgeData({
      message: "",
      status: undefined,
      icon: undefined,
      open: false,
    });
  }

  const closeAlert = () => {
    setBadgeData({
      ...badgeData,
      open: false,
    }
    )
  }


  return (
    <div style={{ height: "100%" }}>
      <LayoutWrapper>
        <LayoutContent>
          <CocktailView>
            <Background />
            <div className="content-wrapper">
              <div className="content-row" >
                <div className="cocktail-form">
                  <div className="form-row title-form">
                    <div className="logo">
                      <img alt="logo" src="/images/logos/logo-dynamic.svg" />
                    </div>
                  </div>
                  <Title variant="h6" style={{ alignSelf: "flex-start" }}>
                    Register
                  </Title>
                  <div className="form-row">
                    <div className="directions">
                      <p>Welcome aboard the “Dynamic Cocktail”.</p>
                      <p>
                        This event represents a celebration of the reinsurance
                        community. You are about to embark on a journey of
                        knowledge and connection.
                      </p>
                      <p>
                        Please fill out the form to secure your spot in this
                        unforgettable experience.
                      </p>
                    </div>
                  </div>
                  <div className="form-row form-inputs">
                    <form
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <Controller
                          name="name"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              autoFocus
                              label="Name"
                              value={value || ""}
                              onBlur={onBlur}
                              onChange={(e) => onChange(handleNameChange(e))}
                              placeholder="Enter your name"
                              disabled={submitDisable}
                              error={Boolean(errors.name)}
                              helperText={errors.name?.message}
                              inputProps= {{
                                  pattern: '^[A-Za-z]+$',
                                }}
                            
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#2535A8",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#2535A8",
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <Controller
                          name="company"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              label="Company"
                              value={value || ""}
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder="Enter your company"
                              disabled={submitDisable}
                              error={Boolean(errors?.company)}
                              helperText={errors?.company?.message}

                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#2535A8",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#2535A8",
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <Controller
                          name="email"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              label="Email"
                              value={value || ""}
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder="Enter your e-mail"
                              disabled={submitDisable}
                              type="email"
                              error={Boolean(errors?.email)}
                              helperText={errors?.email?.message}
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#2535A8",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#2535A8",
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <Controller
                          name="areaCode"
                          control={control}
                          render={() => (
                            <CountrySelect
                              id={selectedCountry?.id}
                              size="medium"
                              otherProps={{ width: "100%" }}
                              disabled={submitDisable}
                              setSelectedCountry={setSelectedCountry}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: "25px" }}>
                        <Controller
                          name="phone"
                          defaultValue={""}
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              label="Phone"
                              value={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              maxLength='10'
                              placeholder="Enter your phone"
                              error={Boolean(errors?.phone)}
                              helperText={errors?.phone?.message}
                              type="number"
                              onInput={(e)=>{ 
                                // Developer hack to limit max lent to 10 (input prop "maxLength not always work")
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                              }}
                              inputProps={{
                                maxLength: 10,
                              }}
                              InputProps={{
                                inputProps: {
                                  pattern: "[0-9]*"
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    +{selectedCountry?.suffix}
                                  </InputAdornment> 
                                ),
                              }}
                              disabled={
                                selectedCountry?.suffix === undefined ||
                                selectedCountry?.suffix === null || 
                                submitDisable
                              }
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#2535A8",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#2535A8",
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <Controller
                          name="role"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <TextField
                              label="Role"
                              value={value || ""}
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder="Enter your role"
                              disabled={submitDisable}
                              error={Boolean(errors?.role)}
                              helperText={errors?.role?.message}
                              sx={{
                                "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#2535A8",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#2535A8",
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "8px",
                                },
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </form>
                  </div>
                  <div className="form-row cocktail-btn">
                    <Button
                      fullWidth
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                      disabled={submitDisable}
                      sx={{
                        height: "43px",
                        position: "relative",
                        backgroundColor: "#2535A8",
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: "#2535A8",
                        },
                      }}
                      // disabled={isLoading}
                    >
                      SUBMIT
                      {isLoading ? (
                        <CircularProgress
                          color="secondary"
                          size={25}
                          sx={{ ml: 25, position: "absolute" }}
                        />
                      ) : null}
                    </Button>
                  </div>
                  {badgeData.open && (<Box
                    className='download-poppups'
                    sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', height: '45px' }}
                  >
                    <CustomAlert props={badgeData} onClose={() => closeAlert()} />
                  </Box>
                  )

                  }
                </div>
              </div>
              <div className="content-row footer-row" >
                <Footer>A Dynamic Reinsurance platform, © 2024</Footer>
              </div>
            </div>
          </CocktailView>
        </LayoutContent>
      </LayoutWrapper>
    </div>
  );
};

export default DynamicCocktail;

const CocktailView = styled.section`
  .logo img {
    max-width: 140px;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;

    div {
      width: 68%;
      height: 0;
      padding: 89% 0;
      position: relative;
    }
    div > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content-row {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
  }

  .content-row.footer-row {
    justify-content: flex-start;
    margin-top: 35px;
  }

  .cocktail-form {
    background-color: white;
    padding: 50px 30px;
    margin-top: 45px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .title-img {
    margin-left: 15px;
  }

  .title-form {
    justify-content: center;
    margin-bottom: 20px;
  }

  .form-inputs {
    max-width: 400px;
    margin: 0;
    padding: 7px 0 0px;
  }

  .form-row {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .directions {
    justify-content: center;
    font-family: "Inter";
    color: rgba(76, 78, 100, 0.6);
    p {
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
  .download-poppups{
    margin-top: 25px;
    
  }
  .download-poppups .MuiSnackbar-root {
  position: inherit !important;
  width: 100%;
  }
  .download-poppups .MuiAlert-root{
    width:100%;  
    border-radius: 10px;
  }


  @media screen and (width < 768px) {
    .background {
      background-color: white;
    }
    .background div {
      display: none;
    }
    .cocktail-form {
      box-shadow: none;
      width: 90%;
      margin-top: 0;
    }
    .form-row {
      max-width: unset;
    }
    .content-row.footer-row {
    justify-content: center;
    margin-top: 0;
    h6{
      margin: 0 0 35px 0;
    }
  }

  }

  @media screen and (width < 480px) {
   
    .cocktail-form {
      width: 100%;
    }

  }
`;

const Title = styled.h6`
  margin: 0px;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(77, 80, 98, 0.87);
  font-family: Inter, sans-serif;
`;

const Footer = styled.h6`
  margin: 0 0 15px 20px;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(77, 80, 98, 0.87);
  font-family: Inter, sans-serif;
  @media screen and (width < 410px) {
   text-align: center;
   font-size: 0.75rem;
  }
`;

const LayoutWrapper = styled.div`
  height: 100vh;
`;

const LayoutContent = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
`;
